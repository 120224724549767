<!-- 非上市企业基本信息 -->
<template>
  <div class="convertibleDetail">
    <div>
      <div class="table-title"></div>
      <cTableItem
        v-for="(item, index) in showList.even"
        :titleArr="[
          `${item.name}`,
          `${showList.odd[index] ? showList.odd[index].name : ''}`,
        ]"
        :key="index"
        :contentArr="[
          `${item.info}`,
          `${showList.odd[index] ? showList.odd[index].info : ''}`,
        ]"
      ></cTableItem>
         <cTableItem
        :titleArr="['公司网站']"
        type="one"
        :itemHref="[formData.gswz?formData.gswz:formData.qygw]"
        :contentArr="[formData.gswz?formData.gswz:formData.qygw]"
      ></cTableItem>
      <cTableItem
        :titleArr="['经营范围']"
        :bigLine="true"
        type="one"
        :contentArr="[formData.jyfw]"
        v-if="formData.jyfw"

      ></cTableItem>
      <cTableItem
        :titleArr="['重要事项']"
        :bigLine="true"
        type="one"
        :contentArr="[formData.zysx]"
        v-if="formData.zysx"
      ></cTableItem>
      <cTableItem
        :titleArr="['简介']"
        type="one"
        :bigLine="true"
        :contentArr="[formData.ssqk == '非上市' ? formData.gsjj : formData.jj]"
      ></cTableItem>
    </div>
  </div>
</template>

<script>
import cTableItem from "@/components/cTableItem";
export default {
  name: "convertibleDetail",
  components: {
    cTableItem,
  },
  props: ["formData"],
  mounted() {
    this.init();
  },
  watch: {
    formData(val) {
      this.init();
    },
  },
  data() {
    return {
      indexList: {
        gsmc: "公司名称",
        ywmc: "英文名",
        cym: "曾用名",
        fddbr: "法定代表人",
        // fddbr1: "法定代表人",
        // frdb: "法定代表人",
        zczb: "注册资本",
        zczb1: "注册资本",
        clrq: "成立日期",
        clrq1: "成立日期",
        ssdq: "所属地区",
        ssdq1: "所属地区",
        dzxx: "邮箱",
        yx: "邮箱",
        lxdh: "电话",
        dh: "电话",
        bgdz: "地址",
        dz: "地址",
        jyzt: "经营状态",
        hzrq: "核准日期",
        dsz: "董事长",
        sjzb: "实缴资本",
        tyshxydm: "统一社会信用代码",
        zch: "注册号",
        gsbh: "公司编号",
        zcdz: "企业地址",
        qydz: "企业地址",
        nsrsbh: "纳税人识别号",
        zzjgdm: "组织机构代码",
        sszjhxy: "所属行业",
        sshy: "所属行业",
        djjg: "登记机关",
        cbrs: "参保人数",
        rygm: "人员规模",
        yyqx: "营业期限",
        qylx: "企业类型",
        // jyfw: "经营范围",
        jsrq: "已告解散日期/不再是独立实体日期",
        gb: "股本",
        // zysx: "*重要事项",
        qpms: "清盘模式",
        yjdjc: "押记登记册",
        // gsjj: "*简介",
        // jj: "*简介",
        // gswz: "企业官网",
        // qygw: "企业官网",
        gxrq: "更新日期",
        cz: "传真",
        qy: "区域",
        lssws: "律师事务所",
        dzyx: "电子邮箱",
      },
      showList: {
        odd: [],
        even: [],
      },
    };
  },
  methods: {
    async init() {
      let list = await this.changeData();
      this.showList = {
        odd: [],
        even: [],
      };
      for (let t in list) {
        if (t % 2 == 0) {
          this.showList.even.push(list[t]);
        } else if (t % 2 == 1) {
          this.showList.odd.push(list[t]);
        }
      }
    },
    changeData() {
      let arr = [];
      for (let i in this.indexList) {
        if (this.formData[i]) {
          let name = this.indexList[i];
          arr.push({
            name: name,
            info: this.formData[i],
          });
        }
      }
      return arr;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/common.scss";
.convertibleDetail {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background: #fff;
  padding: 20px;
  h1 {
    font-size: 20px;
    font-weight: bold;
    color: rgba(51, 51, 51, 1);
    line-height: 26px;
    margin-bottom: 20px;
  }
  div.more {
    height: 40px;
    background: rgba(250, 250, 253, 1);
    color: #00b6ff;
    line-height: 40px;
    font-size: 14px;
    text-align: center;
    margin-top: 10px;
    cursor: pointer;
  }
  div {
    font-size: 14px;
    color: rgba(51, 51, 51, 1);
    div.table-title {
      @include flex(flex-start);
      height: 1px;
      border-bottom: 1px solid rgba(214, 215, 217, 1);
    }
    div {
      @include flex;
      height: 40px;
      line-height: 40px;
      .line-title:nth-of-type(1) {
        border-left: 1px solid rgba(214, 215, 217, 1);
      }
      .line-title {
        background: rgba(250, 250, 253, 1);
        width: 414px;
        border-right: 1px solid rgba(214, 215, 217, 1);
        border-bottom: 1px solid rgba(214, 215, 217, 1);
      }
      .line-content {
        background: #fff;
        width: 414px;
        border-right: 1px solid rgba(214, 215, 217, 1);
        border-bottom: 1px solid rgba(214, 215, 217, 1);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: center;
      }
    }
  }
}
</style>
