var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"convertibleDetail"},[_c('div',[_c('div',{staticClass:"table-title"}),_vm._l((_vm.showList.even),function(item,index){return _c('cTableItem',{key:index,attrs:{"titleArr":[
        `${item.name}`,
        `${_vm.showList.odd[index] ? _vm.showList.odd[index].name : ''}`,
      ],"contentArr":[
        `${item.info}`,
        `${_vm.showList.odd[index] ? _vm.showList.odd[index].info : ''}`,
      ]}})}),_c('cTableItem',{attrs:{"titleArr":['公司网站'],"type":"one","itemHref":[_vm.formData.gswz?_vm.formData.gswz:_vm.formData.qygw],"contentArr":[_vm.formData.gswz?_vm.formData.gswz:_vm.formData.qygw]}}),(_vm.formData.jyfw)?_c('cTableItem',{attrs:{"titleArr":['经营范围'],"bigLine":true,"type":"one","contentArr":[_vm.formData.jyfw]}}):_vm._e(),(_vm.formData.zysx)?_c('cTableItem',{attrs:{"titleArr":['重要事项'],"bigLine":true,"type":"one","contentArr":[_vm.formData.zysx]}}):_vm._e(),_c('cTableItem',{attrs:{"titleArr":['简介'],"type":"one","bigLine":true,"contentArr":[_vm.formData.ssqk == '非上市' ? _vm.formData.gsjj : _vm.formData.jj]}})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }