<!-- 上市企业基本信息 -->
<template>
	<div class="convertibleDetail">
		<div>
			<div class="table-title"></div>
			<cTableItem v-for="(item, index) in showList.even" :titleArr="[
          `${item.name}`,
          `${showList.odd[index] ? showList.odd[index].name : ''}`,
        ]" :key="index" :contentArr="[
          `${item.info}`,
          `${showList.odd[index] ? showList.odd[index].info : ''}`,
        ]"></cTableItem>

			<cTableItem :titleArr="['公司网站']" type="one" :itemHref="[formData.gswz]" :contentArr="[formData.gswz]">
			</cTableItem>
			<cTableItem :titleArr="['经营范围']" type="one" :bigLine="true" :contentArr="[formData.jyfw]"></cTableItem>
			<cTableItem :titleArr="['公司简介']" type="one" :bigLine="true" :contentArr="[formData.gsjj]"></cTableItem>
		</div>
	</div>
</template>

<script>
	import cTableItem from "@/components/cTableItem";
	import {
		returnFloat
	} from "@/utils";

	export default {
		name: "convertibleDetail",
		components: {
			cTableItem,
		},
		props: ["formData"],
		mounted() {
			this.init();
		},
		watch: {
			formData(val) {
				this.init();
			},
		},
		data() {
			return {
				indexList: {
					gpdm: "股票代码",
					gsmc: "企业全称",
					gsjc: "企业简称",
					zzjgdm: "工商登记",
					cym: "曾用名全称",
					ssrq: "上市日期",
					wsfxrq: "网上发行日期",
					ssjys: "上市交易所",
					ssbk: "所属板块",
					zqlb: "证券类别",
					zqlx: "证券类型",
					sszt: "上市状态",
					zprq: "摘牌日期",
					isin: "ISIN代码",
					ywmc: "英文名称",
					ywjc: "英文简称",
					clrq: "成立日期",
					dw: "单位",
					bz: "币种",
					zczb: "注册资本",
					fddbr: "法定代表人",
					// frdb: "法定代表人",
					zyyw: "主营业务",
					// jyfw:'经营范围',
					sf: "省份",
					cs: "城市",
					zcdz: "注册地址",
					bgdz: "办公地址",
					yzbm: "邮政编码",
					lxdh: "公司电话",
					cz: "公司传真",
					dzxx: "公司电子邮件地址",
					// gswz: "公司网站",
					zjhyj: "证监会一级行业名称",
					zjhej: "证监会二级行业名称",
					swyj: "申万行业一级名称",
					swej: "申万行业二级名称",
					swsj: "申万行业三级名称",
					dsz: "董事长",
					dlds: "独立董事",
					zjl: "总经理",
					dm: "董事会秘书",
					dmdh: "董秘电话",
					dmcz: "董秘传真",
					dmyx: "董秘邮箱",
					zqswdb: "证券事务代表",
					hjssws: "会计师事务所",
					lssws: "律师事务所",
					glryrs: "管理人员人数",
					gyrs: "雇员人数",
					njr: "年结日",
				},
				showList: {
					odd: [],
					even: [],
				},
			};
		},
		methods: {
			async init() {
				let list = await this.changeData();
				this.showList = {
					odd: [],
					even: [],
				};
				for (let t in list) {
					if (t % 2 == 0) {
						this.showList.even.push(list[t]);
					} else if (t % 2 == 1) {
						this.showList.odd.push(list[t]);
					}
				}
			},
			changeData() {
				let arr = [];
				if (this.formData.zczb) {
					this.formData.zczb = returnFloat(this.formData.zczb, 2);
				}
				this.formData.glryrs = Number(this.formData.glryrs);
				this.formData.gyrs = Number(this.formData.gyrs);
				for (let i in this.indexList) {
					if (this.formData[i]) {
						let name = this.indexList[i];
						arr.push({
							name: name,
							info: this.formData[i],
						});
					}
				}
				return arr;
			},
		},
	};
</script>
<style lang="scss" scoped>
	@import "@/style/common.scss";

	.convertibleDetail {
		width: 100%;
		height: 100%;
		box-sizing: border-box;
		background: #fff;
		padding: 20px;

		h1 {
			font-size: 20px;
			font-weight: bold;
			color: rgba(51, 51, 51, 1);
			line-height: 26px;
			margin-bottom: 20px;
		}

		div.more {
			height: 40px;
			background: rgba(250, 250, 253, 1);
			color: #00b6ff;
			line-height: 40px;
			font-size: 14px;
			text-align: center;
			margin-top: 10px;
			cursor: pointer;
		}

		div {
			font-size: 14px;
			color: rgba(51, 51, 51, 1);

			div.table-title {
				@include flex(flex-start);
				height: 1px;
				border-bottom: 1px solid rgba(214, 215, 217, 1);
			}

			div {
				@include flex;
				height: 40px;
				line-height: 40px;

				.line-title:nth-of-type(1) {
					border-left: 1px solid rgba(214, 215, 217, 1);
				}

				.line-title {
					background: rgba(250, 250, 253, 1);
					width: 414px;
					border-right: 1px solid rgba(214, 215, 217, 1);
					border-bottom: 1px solid rgba(214, 215, 217, 1);
				}

				.line-content {
					background: #fff;
					width: 414px;
					border-right: 1px solid rgba(214, 215, 217, 1);
					border-bottom: 1px solid rgba(214, 215, 217, 1);
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					text-align: center;
				}
			}
		}
	}
</style>
